import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import ArrowLink from "src/components/ArrowLink"
import { usePages } from "src/context/pages"
import { createOptionFromLinkEntry } from "src/components/Header/navigation"
import Link from "src/utils/Link"

const TeamCard: FC<Props> = ({ description, title, link, className }) => {
  const { pages } = usePages()
  const url = createOptionFromLinkEntry(link, pages)
  return (
    <Link to={url.url} className={`${styles.card} ${className}`}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <ArrowLink
        as="span"
        text={url.name}
        to={url.url}
        className={styles.link}
      />
    </Link>
  )
}

export default TeamCard
